html, body{
  padding: 0;
  margin: 0;
}
#root{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
/*整体布局*/
#RootContainer{
  flex: 1;
  display: flex;
}
/*!*侧边栏*!*/
.mainLayout.ant-layout.ant-layout-has-sider{
  height: 1px;
}
.ant-layout-sider-trigger{
  position: absolute !important;
}
.mainMenu.ant-menu-dark.ant-menu-root.ant-menu-inline,.mainMenu.ant-menu-dark.ant-menu-root.ant-menu-inline-collapsed {
  height: calc(100% - 60px);
  overflow: auto;
}
/*侧边栏滚动条*/
/*滚动条整体部分，可以设置宽度*/
.mainMenu.ant-menu-dark.ant-menu-root.ant-menu-inline::-webkit-scrollbar,.mainMenu.ant-menu-dark.ant-menu-root.ant-menu-inline-collapsed::-webkit-scrollbar{
  width: 5px;
}
/*滚动的滑块*/
.mainMenu.ant-menu-dark.ant-menu-root.ant-menu-inline::-webkit-scrollbar-thumb,.mainMenu.ant-menu-dark.ant-menu-root.ant-menu-inline-collapsed::-webkit-scrollbar-thumb{
  border-radius: 8px;
  background-color: #1677ff;
}
/*外层轨道*/
.mainMenu.ant-menu-dark.ant-menu-root.ant-menu-inline::-webkit-scrollbar-track,.mainMenu.ant-menu-dark.ant-menu-root.ant-menu-inline-collapsed::-webkit-scrollbar-track{
  border-radius: 8px;
}
.mainContainer{
  flex: 1;
  margin: 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  min-width: 1024px;
}
.mainContainer .mainTableSearchWrap{
  background: #FFFFFF;
  padding: 24px 24px 0 24px;
  margin-bottom: 24px;
  border-radius: 4px;
  overflow: auto;
}
.mainContainer .mainTableSearchWrap .tableSearchForm{
  display: flex;
  flex-wrap: wrap;
}
.mainContainer .mainTableSearchWrap .tableSearchForm .hideFormItem{
  display: none;
}
.mainContainer .mainTableSearchWrap .tableSearchForm .ant-form-item{
  width: 24%;
}
.mainContainer .mainTableSearchWrap .tableSearchForm .ant-form-item .ant-form-item-label{
  min-width: 100px;
}
.mainContainer .mainTableSearchWrap .tableSearchForm .ant-form-item .ant-form-item-control{
  flex-shrink: 1;
  flex: none;
  width: 180px;
}
.mainContainer .mainTableSearchWrap .tableSearchForm .searchButtonWrap {
  flex: 1;
  height: 32px;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-end;
}

.mainTableContainer{
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #FFFFFF;
  overflow: hidden;
  padding: 16px 24px;
}
.mainTableContainer .tableTop{
  margin-bottom: 24px;
}
.mainTable{
  flex: 1;
  overflow: auto;
}
.mainTable .ant-table-cell::before{
  display: none;
}
.tableOperationButton{
  cursor: pointer;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0177FB;
}
.tableOperationButtonDisable{
  cursor: not-allowed;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.tableOperationRedButton{
  cursor: pointer;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #F10000;
}
.modalTitle{
  position: relative;
  padding-left: 10px;
}
.modalTitle::before{
  content: '';
  position: absolute;
  width: 3px;
  left: 0;
  top: 17%;
  bottom: 17%;
  background: #0177FB;
  border-radius: 1px;
}
.modalForm.ant-form-horizontal{
  display: flex;
  flex-wrap: wrap;
  margin-right: 10%;
  padding-bottom: 30px;
}
.modalForm.ant-form-horizontal .ant-form-item .ant-form-item-row .ant-form-item-label{
  width: 200px;
}
.modalForm.ant-form-horizontal .ant-form-item .ant-form-item-row .ant-form-item-control{
  width: 180px;
  flex: none;
}
.modalForm.ant-form-horizontal .ant-form-item .ant-form-item-row .ant-form-item-control .ant-input-number{
  width: 100%;
}
.modalForm.ant-form-horizontal .ant-form-item.flex1{
  width: 100%;
}
.modalForm.ant-form-horizontal .ant-form-item.flex1{
  width: 50%;
}
.modalForm.ant-form-horizontal .ant-form-item.flex1 .ant-form-item-control{
  flex: 1;
}
.modalForm.ant-form-horizontal .modalFormBottom{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 24px;
  display: flex;
  justify-content: center;
}
.modalForm.ant-form-horizontal .modalFormBottom .ant-btn + .ant-btn{
  margin-left: 42px;
}
.deleteModal .deleteModalTitle{
  position: relative;
  padding-left: 25px;
}
.deleteModal .deleteModalTitle::before{
  content: '';
  position: absolute;
  width: 16px;
  height: 15px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("./assets/deleteModalIcon.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.deleteModal .ant-modal-body{
  padding: 0 24px 18px 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2A2A37;
}
.deleteModal .ant-modal-footer{
  border: none;
}

.clickCursor{
  cursor: pointer;
  color: #0177FB;
}

